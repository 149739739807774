import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppLayout from './Components/AppLayout/AppLayout';
import Home from './Components/Home/Home';
import ContactPage from './Components/Contact/ContactPage';
import ManageBooking from './Components/Search/ManageBooking';
import Hotels from './Components/Search/Hotels';

import './main.scss'

import FlightDashboard from './Components/Dashboard/FlightDashboard';
import HotelDashboard from './Components/Dashboard/HotelDashboard';
import FlightDashboardProvider from './Components/Context/FlightDashboardContext';
import HotelDashboardProvider from './Components/Context/HotelDashboardContext';
import Notfound from './Components/NotFound/Notfound';
import NotfoundFlight from './Components/NotFound/NotFoundFlight';
import LoginFormFlight from './Components/Login/LoginFormFlight';
import LoginFormHotel from './Components/Login/LoginFormHotel';
import PrivateRoute from './Components/Context/PrivateRouteContext';
import RedirectRoutFlight from './Components/Login/Redirect';
import RedirectRoutHotel from './Components/Login/RedirectRout';
// import { Login } from '@mui/icons-material';


function App() {
  return (
    <div>
      <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contactpage" element={<ContactPage />} />
          <Route path="/managebooking" element={<ManageBooking />} />
          <Route path="/hotels" element={<Hotels />} />
          <Route path="/notFound" element={<Notfound />} />
          <Route path="/notFoundPage" element={<NotfoundFlight />} />
          
        </Route>
      </Routes>
    </Router>

    <Router>
      <PrivateRoute>
      <FlightDashboardProvider >
      <Routes>
      <Route path="/shalipopi" element={<LoginFormFlight />} />
      <Route path="/shalipopidashboard" element={
          <RedirectRoutFlight>
            <FlightDashboard>
              <FlightDashboard />
            </FlightDashboard>
           </RedirectRoutFlight>
        } />
      </Routes>
    </FlightDashboardProvider>
    </PrivateRoute>
    </Router>

    <Router>

      <PrivateRoute>
      <Routes>
        <Route path="/oscroh" element={<LoginFormHotel />} />
        <Route path="/oscrohdashboard" element={
          <RedirectRoutHotel>
            <HotelDashboardProvider>
              <HotelDashboard />
            </HotelDashboardProvider>
           </RedirectRoutHotel>
        } />
      </Routes>
        </PrivateRoute> 
    </Router>
    </div>
  )
}

export default App

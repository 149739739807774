

import React, { createContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import html2canvas from 'html2canvas';

export const FlightDashboardContext = createContext();

function FlightDashboardProvider({ children }) {

  const [infos, setInfos] = useState([]);
  const infosCollectionRef = collection(db, "flight_infos");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const htmlRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const getInfos = async () => {
      setLoading(true);
      const data = await getDocs(infosCollectionRef);
      setInfos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false);
    };
    getInfos();
  }, []);

  const send = async (e) => {
    e.preventDefault();

    let generatedNumber;
    do {
      generatedNumber = generateNumber();
    } while (infos.some(info => info.confirmNumber === generatedNumber));

    try {
      await addDoc(infosCollectionRef, {
        Initial_location: e.target[0].value,
        Final_location: e.target[1].value,
        Duration: e.target[2].value,
        First_take_off_time: e.target[3].value,
        First_take_off_day: e.target[4].value,
        First_take_off_location: e.target[5].value,
        First_take_off_airport: e.target[6].value,
        First_flight_time: e.target[7].value,
        First_take_off_airline: e.target[8].value,
        First_landing_time: e.target[9].value,
        First_landing_day: e.target[10].value,
        First_landing_location: e.target[11].value,
        First_landing_airport: e.target[12].value,
        Layoff_time: e.target[13].value,
        Second_take_off_time: e.target[14].value,
        Second_take_off_day: e.target[15].value,
        Second_take_off_location: e.target[16].value,
        Second_landing_airport: e.target[17].value,
        Second_flight_time: e.target[18].value,
        Second_airline: e.target[19].value,
        Second_landing_time: e.target[20].value,
        Second_landing_day: e.target[21].value,
        Final_landing_location: e.target[22].value,
        Final_landing_airport: e.target[23].value,
        firstName: e.target[24].value,
        surName: e.target[25].value,
        email: e.target[26].value,
        confirmNumber: generatedNumber,
      });

      navigate(0);
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  };

  const generateNumber = () => {
    let randomNo = [];
    for (let i = 0; i < 10; i++) {
      randomNo.push(Math.floor(Math.random() * 9) + 1);
    }
    return randomNo.join('');
  };

  const handleDownload = async (info) => {
    setPreviewData(info);
    setIsModalOpen(true);
    setTimeout(async () => {
      const element = htmlRef.current;
      if (element) {
        const canvas = await html2canvas(element);
        const dataUrl = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'download.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, 1000);
  };

  const handleEdit = async (e, info) => {
    e.preventDefault();
    // setIsModalOpen2(true);
    // setIsModalOpen(false);

    try {
      const infoDoc = doc(db, "flight_infos", info.id);
      await updateDoc(infoDoc, {
        Initial_location: e.target[0].value,
        Final_location: e.target[1].value,
        Duration: e.target[2].value,
        First_take_off_time: e.target[3].value,
        First_take_off_day: e.target[4].value,
        First_take_off_location: e.target[5].value,
        First_take_off_airport: e.target[6].value,
        First_flight_time: e.target[7].value,
        First_take_off_airline: e.target[8].value,
        First_landing_time: e.target[9].value,
        First_landing_day: e.target[10].value,
        First_landing_location: e.target[11].value,
        First_landing_airport: e.target[12].value,
        Layoff_time: e.target[13].value,
        Second_take_off_time: e.target[14].value,
        Second_take_off_day: e.target[15].value,
        Second_take_off_location: e.target[16].value,
        Second_landing_airport: e.target[17].value,
        Second_flight_time: e.target[18].value,
        Second_airline: e.target[19].value,
        Second_landing_time: e.target[20].value,
        Second_landing_day: e.target[21].value,
        Final_landing_location: e.target[22].value,
        Final_landing_airport: e.target[23].value,
        firstName: e.target[24].value,
        surName: e.target[25].value,
        email: e.target[26].value,
      });

      navigate(0);
      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const deleteInfo = async (info) => {
    try {
      const infoDoc = doc(db, "flight_infos", info.id);
      await deleteDoc(infoDoc);
      navigate(0);
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handlePreview = (info) => {
    setPreviewData(info);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const createModal = () => {
    setIsModalOpen2(true);
  };
  const closeCreateModal = () => setIsModalOpen2(false);

  return (
    <FlightDashboardContext.Provider value={{
      infos,
      loading,
      send,
      handleEdit,
      deleteInfo,
      handleDownload,
      handlePreview,
      closeModal,
      createModal,
      closeCreateModal,
      isModalOpen,
      setIsModalOpen,
      isModalOpen2,
      setIsModalOpen2,
      previewData,
      htmlRef,
    }}>
      {children}
    </FlightDashboardContext.Provider>
  );
}

export default FlightDashboardProvider;

import React, { createContext, useEffect, useState } from 'react';
import { db } from '../../firebase-config';
import { collection, getDocs} from 'firebase/firestore';

export const PrivateRouteContext = createContext();

const PrivateRoute = ({ children }) => {
  const [infos, setInfos] = useState([]);
  const infosCollectionRef = collection(db, "login_info");
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInfos = async () => {
      const data = await getDocs(infosCollectionRef);
      setInfos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      
    };
    getInfos();
  }, []);





  return (
    <PrivateRouteContext.Provider value={{
      infos
    }}>
      {children}
    </PrivateRouteContext.Provider>
  )
}

export default PrivateRoute






// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { PrivateRouteContext } from './PrivateRouteContext';

// // Dummy authentication check function
// const isAuthenticated = () => {
    
//   return localStorage.getItem('authToken') !== null;
// };

// const PrivateRoute = ({ element }) => {
//   return isAuthenticated() ? element : <Navigate to="/login" />;
// };

// export default PrivateRoute;

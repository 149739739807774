import React, { useState, useEffect, useRef, useContext  } from 'react';
import { FlightDashboardContext } from '../Context/FlightDashboardContext';

// import {db} from '../../firebase-config'
// import {collection, getDocs, addDoc, updateDoc, doc, deleteDoc} from 'firebase/firestore'
// import { useNavigate } from 'react-router-dom';
// import html2canvas from 'html2canvas';

const FlightDashboard = () => {
  const {
      infos,
      loading,
      send,
      handleEdit,
      deleteInfo,
      handleDownload,
      handlePreview,
      closeModal,
      createModal,
      closeCreateModal,
      isModalOpen,
      setIsModalOpen,
      isModalOpen2,
      setIsModalOpen2,
      previewData,
      htmlRef,} = useContext(FlightDashboardContext)
  // const [isOpen, setIsOpen] = useState(false);
  // const [infos, setInfos] = useState([]);
  // const infosCollectionRef = collection(db, "flight_infos");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalOpen2, setIsModalOpen2] = useState(false);
  // const [previewData, setPreviewData] = useState({});
  // const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  // const htmlRef = useRef(null);
  const [initialLocation, setInitialLocation] = useState('');
  const [FinalLocation, setFinalLocation] = useState('');
  const [duration, setDuration] = useState('');
  const [finalLandingAirport, setFinalLandingAirport] = useState('');
  const [FinalLandingLocation, setFinalLandingLocation] = useState('')
  const [FirstFlightTime, setFirstFlightTime] = useState('');
  const [Second_landing_day, setSecondLandingDay] = useState('');
  const [Second_landing_time, setSecondLandingTime] = useState('');
  const [Second_take_off_day, setSecondTakeOffDay] = useState('');
  const [Second_take_off_location, setSecondTakeOffLocation] = useState('');
  const [Second_take_off_time, setSecondTakeOffTime] = useState('');
  // const [Total_time, setTotalTime] = useState('');
  const [Layoff_time, setLayoffTime] = useState('');
  const [Second_airline, setSecondAirline] = useState('');
  const [Second_flight_time, setSecondFlightTime] = useState('');
  const [Second_landing_airport, setSecondLandingAirport] = useState('');
  const [First_take_off_airline, setFirstTakeOffAirline] = useState('');
  const [First_take_off_airport, setFirstTakeOffAirport] = useState('');
  const [First_take_off_location, setFirstTakeOffLocation] = useState('');
  const [First_landing_airport, setFirstLandingAirport] = useState('')
  const [First_landing_day, setFirstLandingDay] = useState('');
  const [First_landing_location, setFirstLandingLocation] = useState('')
  const [First_landing_time, setFirstLandingTime] = useState('');
  const [First_take_off_time, setFirstTakeOffTime] = useState('');
  const [First_take_off_day, setFirstTakeOffDay] = useState('');
  const [editFirstName, setEditFirstName] = useState('');
  const [editSurName, setEditSurName] = useState('');
  const [editEmail, setEditEmail] = useState('');
 


  return (
    <>
      <div className="flex">
        <div className="  p-10 texts font-bold">
          <div class="mb-4">
        <input type="text" placeholder="Search tickets" class="w-full p-2 border rounded-lg" />
      </div>
          <div><div className="main_dashboard p-3">
            <div className="main_content my-3">
              <button className='btn btn-primary' onClick={createModal}>Create ticket</button>
              <div className="header_money mb-4 d-flex align-items-center justify-content-between">
                {/* <Sidebar /> */}
                <h1>All tickets </h1>
              </div>

              <ol className="list_tick">
                {!loading && infos.map((info, idx)=> (                
                  <li className="mb-4" key={idx}> 
                    <div className="ticketList flex flex-wrap align-items-center">
                      <h6>{info.Final_location}</h6>
                      <button className="btn ms-4 btn-primary text-white px-4 py-2 rounded-lg me-3" onClick={()=> handlePreview(info)}>preview</button>
                      <button className="btn btn-primary text-white px-4 py-2 rounded-lg me-3" onClick={()=> handleDownload(info)}>download png</button>
                      <button className="bg-red-500 text-white px-4 py-2 rounded-lg" onClick={()=> deleteInfo(info)}>delete</button>
                    </div>
                  </li>
                ))}
                {loading && <li>loading</li>}
              </ol>
            </div>
          </div></div>
        </div>

      </div>


      {/* preview modal */}
      {isModalOpen && (
        <div className="p-4 bg-zinc-100 inset-0">

          
        <div className="max-w-2xl mx-auto bg-white  rounded-lg shadow-md p-6" ref={htmlRef}>
          <h1 className="text-2xl font-bold ">TICKET CONFIRMATION</h1>
          <div className="font-semibold text-lg text-gray-800">Confirmation Number: {previewData.confirmNumber}</div>
          <div className="font-semibold text-lg text-gray-800">Name: {previewData.firstName} {previewData.surName} </div>
          <div className="font-semibold mb-8"> Email: <span className='text-blue-800 mb-4'> {previewData.email}</span></div>
          <div className="text-lg mb-2">
            <span className="font-semibold">{previewData.Initial_location}</span> → <span className="font-semibold">{previewData.Final_location}</span>
            <span className="text-zinc-600 dark:text-zinc-400">Duration: {previewData.Duration}</span>
          </div>
          <div className="space-y-4">
            
            <div className="bg-zinc-50 dark:bg-zinc-200 p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <div>
                  <div className="text-xl font-semibold">{previewData.First_take_off_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_take_off_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.First_take_off_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_take_off_airport}</div>
                </div>
              </div>
              <div className="flex items-center mb-2">
                <div className="flex-1 border-l-2 border-zinc-300 dark:border-zinc-600 pl-4">
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_flight_time}</div>
                  <div className="flex items-center">
                    {/* <img src="https://placehold.co/20x20" alt="United Airlines" className="mr-2"> */}
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded">{previewData.First_take_off_airline}</span>
                  </div>
                  <div className="text-zinc-600 dark:text-zinc-400 mt-2">Connection info</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Carrier</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Connection number</div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-xl font-semibold">{previewData.First_landing_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_landing_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.First_landing_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_landing_airport}</div>
                </div>
              </div>
            </div>
            <div className="flex items-center text-zinc-600 dark:text-zinc-400">
              <span className="mr-2">⏱</span> {previewData.Layoff_time} layover
            </div>
            
            <div className="bg-zinc-50 dark:bg-zinc-200 p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <div>
                  <div className="text-xl font-semibold">{previewData.Second_take_off_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_take_off_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.Second_take_off_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_landing_airport}</div>
                </div>
              </div>
              <div className="flex items-center mb-2">
                <div className="flex-1 border-l-2 border-zinc-300 dark:border-zinc-600 pl-4">
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_flight_time}</div>
                  <div className="flex items-center">
                    {/* <img src="https://placehold.co/20x20" alt="United Airlines" className="mr-2"> */}
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded">{previewData.Second_airline}</span>
                  </div>
                  <div className="text-zinc-600 dark:text-zinc-400 mt-2">Connection info</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Carrier</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Connection number</div>
                </div>
                {/* <img src="https://placehold.co/100x100" alt="QR Code" className="ml-4"> */}
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-xl font-semibold">{previewData.Second_landing_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_landing_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.Final_landing_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Final_landing_airport}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div className="flex justify-end p-2 mt-10">
          <button className='px-4 py-2  btn' onClick={()=> {
            setEdit(true); setIsModalOpen2(true); setIsModalOpen(false);
            setInitialLocation(previewData.Initial_location);
            setFinalLandingAirport(previewData.Final_landing_airport);
            setFinalLocation(previewData.Final_location);
            setDuration(previewData.Duration)
            setFinalLandingLocation(previewData.Final_landing_location);
            setFirstFlightTime(previewData.First_flight_time)
            setFirstFlightTime(previewData.First_flight_time);
            setFirstLandingAirport(previewData.First_landing_airport)
            setFirstLandingDay(previewData.First_landing_day);
            setFirstLandingLocation(previewData.First_landing_location);
            setFirstLandingTime(previewData.First_landing_time);
            setFirstTakeOffAirline(previewData.First_take_off_airline);
            setFirstTakeOffAirport(previewData.First_take_off_airport);
            setFirstTakeOffDay(previewData.First_take_off_day);
            setFirstTakeOffLocation(previewData.First_take_off_location);
            setFirstTakeOffTime(previewData.First_take_off_time);
            setSecondAirline(previewData.Second_airline);
            setSecondFlightTime(previewData.Second_flight_time);
            setSecondLandingAirport(previewData.Second_landing_airport);
            setSecondLandingDay(previewData.Second_landing_day);
            setSecondLandingTime(previewData.Second_landing_time);
            setSecondTakeOffDay(previewData.Second_take_off_day)
            setSecondTakeOffLocation(previewData.Second_take_off_location)
            setSecondTakeOffTime(previewData.Second_take_off_time)
            setEditFirstName(previewData.firstName)
            setEditSurName(previewData.surName)
            setEditEmail(previewData.email)
            }}>edit</button>
          <button onClick={closeModal} className="px-4 py-2 font-semibold text-white bg-red-500 rounded hover:bg-red-700">
          Close
          </button>
         </div>


      </div>
      )}




      
      {/* model */}
      {isModalOpen2 && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-1/3 max-h-full overflow-y-auto">
        {/* <div className="px-4 py-2 text-lg font-semibold border-b">Modal Header</div> */}
        <div className="p-4">
          <form action="" onSubmit={(e)=> edit? handleEdit(e, previewData) : send(e)}>
            <div>
              <label htmlFor="">Initial location</label>
              <input className='border w-full' value={initialLocation} onChange={(e)=> setInitialLocation(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Final location</label>
              <input className='border w-full' value={FinalLocation} onChange={(e)=> setFinalLocation(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Duration</label>
              <input className='border w-full' value={duration} onChange={(e)=> setDuration(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First take-off time</label>
              <input className='border w-full' value={First_take_off_time} onChange={(e)=> setFirstTakeOffTime(e.target.value)} type="time" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First take-off day</label>
              <input className='border w-full' value={First_take_off_day} onChange={(e)=> setFirstTakeOffDay(e.target.value)}  type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First take-off location</label>
              <input className='border w-full' value={First_take_off_location} onChange={(e)=> setFirstTakeOffLocation(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First take-off Airport</label>
              <input className='border w-full' value={First_take_off_airport} onChange={(e)=> setFirstTakeOffAirport(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First flight time</label>
              <input className='border w-full' value={FirstFlightTime} onChange={(e)=> setFirstFlightTime(e.target.value)} type="time" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First take-off Airline</label>
              <input className='border w-full' value={First_take_off_airline} onChange={(e)=> setFirstTakeOffAirline(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First landing time</label>
              <input className='border w-full' value={First_landing_time} onChange={(e)=> setFirstLandingTime(e.target.value)} type="time" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First landing day</label>
              <input className='border w-full' value={First_landing_day} onChange={(e)=> setFirstLandingDay(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First landing location</label>
              <input className='border w-full' value={First_landing_location} onChange={(e)=> setFirstLandingLocation(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First landing Airport</label>
              <input className='border w-full' value={First_landing_airport} onChange={(e)=> setFirstLandingAirport(e.target.value)}  type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Layover time</label>
              <input className='border w-full' value={Layoff_time} onChange={(e)=> setLayoffTime(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second take-off time</label>
              <input className='border w-full' value={Second_take_off_time} onChange={(e)=> setSecondTakeOffTime(e.target.value)} type="time" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second take-off day</label>
              <input className='border w-full' value={Second_take_off_day} onChange={(e)=> setSecondTakeOffDay(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second take-off location</label>
              <input className='border w-full' value={Second_take_off_location} onChange={(e)=> setSecondTakeOffLocation(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second landing airport</label>
              <input className='border w-full' value={Second_landing_airport} onChange={(e)=> setSecondLandingAirport(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second flight time</label>
              <input className='border w-full' value={Second_flight_time} onChange={(e)=> setSecondFlightTime(e.target.value)} type="time" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second Airline</label>
              <input className='border w-full' value={Second_airline} onChange={(e)=> setSecondAirline(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second landing time</label>
              <input className='border w-full' value={Second_landing_time} onChange={(e)=> setSecondLandingTime(e.target.value)} type="time" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Second landing day</label>
              <input className='border w-full' value={Second_landing_day} onChange={(e)=> setSecondLandingDay(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Final landing location</label>
              <input className='border w-full' value={FinalLandingLocation} onChange={(e)=> setFinalLandingLocation(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Final landing Airport</label>
              <input className='border w-full' value={finalLandingAirport} onChange={(e)=> setFinalLandingAirport(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">First Name</label>
              <input className='border w-full' value={editFirstName} onChange={(e)=>  setEditFirstName(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Surname</label>
              <input className='border w-full' value={editSurName} onChange={(e)=> setEditSurName(e.target.value)} type="text" name="" id="" />
            </div>
            <div>
              <label htmlFor="">Email</label>
              <input className='border w-full' value={editEmail} onChange={(e)=> setEditEmail(e.target.value)} type="text" name="" id="" />
            </div>
            <button className='btn btn-primary'>send</button>
          </form>
        </div>
        <div className="flex justify-end p-2">
          <button onClick={closeCreateModal} className="px-4 py-2 font-semibold text-white bg-red-500 rounded hover:bg-red-700">
            Close
          </button>
        </div>
      </div>
    </div>
      )}
    </>
  );
};


export default FlightDashboard;




import React from 'react'
import notFoundImg from '../../assets/no-result-flight-search.png'

const NotfoundFlight = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4 pt-[200px]">
          <div className="bg-white p-6 text-center max-w-lg w-full">
            <img src={notFoundImg} alt="" className="mb-4 mx-auto" />
            <h2 className="text-2xl font-bold text-zinc-800 mb-2">We couldn't find your trip.</h2>
            <p className="text-zinc-600 mb-4">
              We've searched more than 400 airlines that we sell, and couldn't find any flights.
            </p>
            <h3 className="text-lg font-semibold text-zinc-700 mb-2">Here are some tips to help find flights</h3>
            <ul className="text-zinc-600">
              <li>Adjust your arrival or departure to a nearby airport</li>
              <li>Try arriving or departing one day earlier or later.</li>
            </ul>
          </div>
        </div>
    )
}

export default NotfoundFlight;
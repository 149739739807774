import React, {useEffect, useState, useRef} from 'react';
import ticket from '../../assets/get-ticket.svg';
import {db} from '../../firebase-config'
import {collection, getDocs, addDoc, updateDoc, doc, deleteDoc} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';

const ManageBooking = () => {
  const [infos, setInfos] = useState([]);
  const infosCollectionRef = collection(db, "flight_infos");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const htmlRef = useRef(null);
  const [previewData, setPreviewData] = useState({});


  
  useEffect(()=> {
    const getInfos = async ()=> {
      setLoading(true)
      const data = await getDocs(infosCollectionRef)
      setInfos(data.docs.map((doc)=> ({...doc.data(), id: doc.id })));
      setLoading(false)
    }
    getInfos();
  }, [])
  
  const closeModal = () => setIsModalOpen(false);

  
  const handleDownload = (info) => {
    setPreviewData(info)
    setIsModalOpen(true);
    setTimeout(async () => {
      const element = htmlRef.current;
  
      console.log(element, document.querySelector('.uuu'), 'hellobb')
      if (element) {
        const canvas = await html2canvas(element);
        const dataUrl = canvas.toDataURL('image/png');
  
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'download.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, 1000);
  };
  

  const checkRefno = (refNo, surname, email)=> {
    let checkRef = infos.filter((info, idx) => {
      return info.confirmNumber === refNo;
    });

    let checkSurname = infos.filter((info, idx) => {
      return info.surName === surname;
    });

    let checkEmail = infos.filter((info, idx) => {
      return info.email === email;
    });

    // console.log(checkRef);


    if(checkRef.length > 0 && checkSurname.length > 0 && checkEmail.length > 0) {
      setPreviewData(checkRef[0]);

      handleDownload(checkRef[0])
      
    }else {
      console.log('no record found')
    }

  }

  const getTicket = (e) => {
    e.preventDefault();

    // console.log(infos, 'boy')
    // console.log(e.target[0].value, 'hello')
    if(e.target[0].value !== '' && e.target[1].value !== '' && e.target[2].value !== '') {
      checkRefno(e.target[0].value, e.target[1].value, e.target[2].value);
    }

  }

 
  return (
    <>
      <div className=' pt-44 pb-30 text-center '>
        <h2 className='text-3xl font-bold'>What would you like to do?</h2>


      <div className="section flex items-center justify-center">
        <div className="bgcustomgray border text-center flex items-center justify-center p-6">
          <div className="flex flex-col items-center">
            <div className="mb-4">
              <img src={ticket} alt="Get Ticket" />
            </div>

            <div>
              <h4 className="text-lg font-semibold">CHECK MY TICKET</h4>
              <p className="mt-2 mb-4">Need to check your ticket? This service allows you to verify your ticket for a paid booking.</p>
            </div>



            <div className=''>
              <form onSubmit={getTicket}>
                <div className='mb-2'><input className='p-2' type='number' placeholder='Booking Number' required/></div>

                <div className='mb-2'><input  className='p-2' type='text' placeholder='Surname' required/></div>

                <div className='mb-4'><input className='p-2' type='text' placeholder='Email' required/></div>

                <button type='submit' className='py-2 px-8 rounded-lg bgcustomBlue text-white'>Submit</button>
              </form>

            </div>



          </div>
        </div>
      </div>




      </div>
    

      {isModalOpen && (
        <div className="p-4 bg-zinc-100 inset-0">

          
        <div className="max-w-2xl mx-auto bg-white  rounded-lg shadow-md p-6" ref={htmlRef}>
          <h1 className="text-2xl font-bold ">TICKET CONFIRMATION</h1>
          <div className="font-semibold text-lg text-gray-800">Confirmation Number: {previewData.confirmNumber}</div>
          <div className="font-semibold text-lg text-gray-800">Name: {previewData.firstName} {previewData.surName} </div>
          <div className="font-semibold mb-8"> Email: <span className='text-blue-800 mb-4'> {previewData.email}</span></div>
          <div className="text-lg mb-2">
            <span className="font-semibold">{previewData.Initial_location}</span> → <span className="font-semibold">{previewData.Final_location}</span>
            <span className="text-zinc-600 dark:text-zinc-400">Duration: {previewData.Duration}</span>
          </div>
          <div className="space-y-4">
            
            <div className="bg-zinc-50 dark:bg-zinc-200 p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <div>
                  <div className="text-xl font-semibold">{previewData.First_take_off_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_take_off_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.First_take_off_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_take_off_airport}</div>
                </div>
              </div>
              <div className="flex items-center mb-2">
                <div className="flex-1 border-l-2 border-zinc-300 dark:border-zinc-600 pl-4">
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_flight_time}</div>
                  <div className="flex items-center">
                    {/* <img src="https://placehold.co/20x20" alt="United Airlines" className="mr-2"> */}
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded">{previewData.First_take_off_airline}</span>
                  </div>
                  <div className="text-zinc-600 dark:text-zinc-400 mt-2">Connection info</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Carrier</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Connection number</div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-xl font-semibold">{previewData.First_landing_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_landing_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.First_landing_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.First_landing_airport}</div>
                </div>
              </div>
            </div>
            <div className="flex items-center text-zinc-600 dark:text-zinc-400">
              <span className="mr-2">⏱</span> {previewData.Layoff_time} layover
            </div>
            
            <div className="bg-zinc-50 dark:bg-zinc-200 p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <div>
                  <div className="text-xl font-semibold">{previewData.Second_take_off_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_take_off_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.Second_take_off_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_landing_airport}</div>
                </div>
              </div>
              <div className="flex items-center mb-2">
                <div className="flex-1 border-l-2 border-zinc-300 dark:border-zinc-600 pl-4">
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_flight_time}</div>
                  <div className="flex items-center">
                    {/* <img src="https://placehold.co/20x20" alt="United Airlines" className="mr-2"> */}
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded">{previewData.Second_airline}</span>
                  </div>
                  <div className="text-zinc-600 dark:text-zinc-400 mt-2">Connection info</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Carrier</div>
                  <div className="text-zinc-600 dark:text-zinc-400">Connection number</div>
                </div>
                {/* <img src="https://placehold.co/100x100" alt="QR Code" className="ml-4"> */}
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-xl font-semibold">{previewData.Second_landing_time}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Second_landing_day}</div>
                </div>
                <div className="text-right">
                  <div className="text-xl font-semibold">{previewData.Final_landing_location}</div>
                  <div className="text-zinc-600 dark:text-zinc-400">{previewData.Final_landing_airport}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div className="flex justify-end p-2 mt-10">
          <button onClick={closeModal} className="px-4 py-2 font-semibold text-white bg-red-500 rounded hover:bg-red-700">
          Close
          </button>
         </div>


      </div>
      )}
    </>
  );
};

export default ManageBooking;

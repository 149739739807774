import React, {useEffect, useState, useRef} from 'react';
import ticket from '../../assets/get-ticket.svg';
import {db} from '../../firebase-config'
import {collection, getDocs, addDoc, updateDoc, doc, deleteDoc} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { CiCalendarDate } from "react-icons/ci";
import { TbListDetails } from "react-icons/tb";
import { IoLocationOutline } from "react-icons/io5";

const Hotels = () => {
  const [infos, setInfos] = useState([]);
  const infosCollectionRef = collection(db, "hotel_infos");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const htmlRef = useRef(null);
  const [previewData, setPreviewData] = useState({});

  useEffect(()=> {
    const getInfos = async ()=> {
      setLoading(true)
      const data = await getDocs(infosCollectionRef)
      setInfos(data.docs.map((doc)=> ({...doc.data(), id: doc.id })));
      setLoading(false)
    }
    getInfos();
  }, [])


  
  const closeModal = () => setIsModalOpen(false);

  
  const handleDownload = (info) => {
    setPreviewData(info)
    setIsModalOpen(true);
    setTimeout(async () => {
      const element = htmlRef.current;
  
      console.log(element, document.querySelector('.uuu'), 'hellobb')
      if (element) {
        const canvas = await html2canvas(element);
        const dataUrl = canvas.toDataURL('image/png');
  
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'download.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, 1000);
  };
  
  
  // const checkRefno = (refNo)=> {
  //   let checkRef = infos.filter((info, idx) => {
  //     return info.confirmNumber === refNo;
  //   });

  //   // console.log(checkRef);


  //   if(checkRef.length > 0) {
  //     setPreviewData(checkRef[0]);

  //     handleDownload(checkRef[0])
      
  //   }else {
  //     console.log('no record found')
  //   }

  // }

  // const getTicket = (e) => {
  //   e.preventDefault();

  //   // console.log(infos, 'boy')
  //   // console.log(e.target[0].value, 'hello')
  //   if(e.target[0].value !== '' && e.target[1].value !== '' && e.target[2].value !== '') {
  //     checkRefno(e.target[0].value);
  //     // checkCodeNo(e.target[0].value);
  //   }

  // }



  const checkRefno = (refNo, code, email)=> {
    let checkRef = infos.filter((info, idx) => {
      return info.confirmNumber === refNo;
    });

    let checkCode = infos.filter((info, idx) => {
      return info.code === code;
    });

    let checkEmail = infos.filter((info, idx) => {
      return info.email === email;
    });

    // console.log(checkRef);


    if(checkRef.length > 0 && checkCode.length > 0 && checkEmail.length > 0) {
      setPreviewData(checkRef[0]);

      handleDownload(checkRef[0])
      
    }else {
      console.log('no record found')
    }

  }

  const getTicket = (e) => {
    e.preventDefault();

    // console.log(infos, 'boy')
    // console.log(e.target[0].value, 'hello')
    if(e.target[0].value !== '' && e.target[1].value !== '' && e.target[2].value !== '') {
      checkRefno(e.target[0].value, e.target[1].value, e.target[2].value);
    }
  }




  return (
    <>
    <div className=' pt-44 pb-30 text-center '>
      <h2 className='text-3xl font-bold'>What would you like to do?</h2>


    <div className="section flex items-center justify-center">
      <div className="bgcustomgray border text-center flex items-center justify-center p-6">
        <div className="flex flex-col items-center">
        <div className="mb-4">
              <img src={ticket} alt="Get Ticket" />
            </div>

          <div>
            <h4 className="text-lg font-semibold">Verify My Hotel Booking</h4>
            <p className="mt-2 mb-4">Need to verify your hotel booking? This service allows you to confirm the details of your paid reservation.</p>
          </div>



          <div className=''>
             <form onSubmit={getTicket}>
              <div className='mb-2'><input required className='p-2' placeholder='Reference Number'/></div>

              <div className='mb-2'><input  className='p-2' placeholder='Code' required/></div>

              <div className='mb-4'><input className='p-2' placeholder='Email' required/></div>

              <button type='submit' className='py-2 px-8 rounded-lg bgcustomBlue text-white'>Submit</button>
             
              
             </form>
          </div>



        </div>
      </div>
    </div>




    </div>


    {isModalOpen && (
        <div className="p-4 bg-zinc-100 inset-0">
        <div className="max-w-2xl mx-auto  rounded-lg shadow-md p-6" ref={htmlRef}>
        {/* <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md"> */}
          <h1 className="text-2xl font-bold text-blue-600 dark:text-blue-400">{previewData.destination}</h1>
          <div className="flex justify-between mt-4">
            <div className="flex items-start">
                <CiCalendarDate />
              <div>
                <p className="text-black">Check-in</p>
                <p className="font-semibold">{previewData.checkInDate}</p>
                <p className="text-zinc-600">{previewData.checkInTime}</p>
              </div>
            </div>
            <div className="flex items-start">
              {/* <img src="https://placehold.co/24x24" alt="calendar" className="mr-2"> */}
              <div>
                <p className="text-black">Check-out</p>
                <p className="font-semibold">{previewData.checkOutDate}</p>
                <p className="text-zinc-600">{previewData.checkOutTime}</p>
              </div>
            </div>
          </div>
          <div className="mt-4 p-2 bg-green-100 dark:bg-green-900 rounded-md">
            <p className="text-zinc-600 dark:text-zinc-300">Confirmation number: <span className="font-semibold text-black dark:text-white">{previewData.confirmNumber}</span></p>
            <p className="text-zinc-600 dark:text-zinc-300">PIN code: <span className="font-semibold text-black dark:text-white">{previewData.code}</span></p>
          </div>
          <div className="flex items-start mt-4">
          <TbListDetails />
            <div>
              <p className="font-semibold text-black">Booking details</p>
              <p className="text-zinc-600">{previewData.adults} adults - {previewData.nights} nights, {previewData.rooms} room</p>
            </div>
          </div>
          <div className="flex items-start mt-4">
          <IoLocationOutline />
            <div>
              <p className="font-semibold text-black">Address</p>
              <p className="text-zinc-600">{previewData.address}</p>
            </div>
          </div>
        {/* </div> */}
        
        
        </div>

          <div className="flex justify-end p-2 mt-10">
          <button onClick={closeModal} className="px-4 py-2 font-semibold text-white bg-red-500 rounded hover:bg-red-700">
          Close
          </button>
         </div>


      </div>
      )}

    </>
  );
};

export default Hotels;

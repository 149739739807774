import React from 'react';
import Hero from '../Hero/Hero';
import Search from '../Search/Search';
import Support from './../Support/Support';
import Info from '../Info/Info';
import Lounge from './../Lounge/Lounge';
import Travelers from './../Travelers/Travelers';
import Subscriber from './../Subscribers/Subscribe';
import FlightDashboardProvider from '../Context/FlightDashboardContext';
// import './main.scss'


const Home = () => {

  return (
    <div>
      {/* <Navbar /> */}

      <FlightDashboardProvider >
      <Hero />
      <Search />
      <Support />
      <Info />
      <Lounge />
      <Travelers />
      <Subscriber />

       {/* <Dashboard /> */}


      {/* <Footer /> */}
      </FlightDashboardProvider>
    </div>
  )
}

export default Home
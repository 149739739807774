import { useEffect, useState, useRef } from 'react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { RiAccountPinCircleLine } from 'react-icons/ri';
import { RxCalendar } from 'react-icons/rx';
import { MdAirplanemodeActive } from "react-icons/md";
import { MdOutlineHotel } from "react-icons/md";
import Hotel from './Hotel';
import Book from './Book';



function Search() {
  const book = useRef(null)
  const hotel = useRef(null)
  const [isBook, setIsBook]= useState(true)
  const [isHotel, setIsHotel]= useState(false)
  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  // }, []);



  useEffect(()=>{
    const book_ele = book.current;
        book_ele.classList.add('active_booking_button')
    },[])
    

  const activeBooking = (e)=>{
    const book_ele = book.current;
    const hotel_ele = hotel.current;

    const ele = e.currentTarget;
    const text = e.currentTarget.textContent;
    ele.classList.add('active_booking_button')

    if(text ==='Book'){
      hotel_ele.classList.remove('active_booking_button')
      setIsHotel(false);setIsBook(true)
    } 
    else if(text ==='Hotels'){
      book_ele.classList.remove('active_booking_button')
      setIsBook(false);setIsHotel(true);
    }
  }

  return (
    <div className="search section container">
      <div className='flex gap-10px'>
      <div className="booking_button" onClick={activeBooking}ref={book}><MdAirplanemodeActive />Book</div>
        <div className="booking_button" onClick={activeBooking}ref={hotel}><MdOutlineHotel />Hotels</div>
        </div>

      <div>
      {isBook&&<Book/>}
        {isHotel&&<Hotel/>}
      </div>
    </div>
  );
}

export default Search;




// import { useEffect, useState } from 'react'
// import { HiOutlineLocationMarker } from 'react-icons/hi'
// import { RiAccountPinCircleLine } from 'react-icons/ri'
// import { RxCalendar } from 'react-icons/rx'

// // import Aos from 'aos'
// // import 'aos/dist/aos.css'

// function Search() {
//   // useEffect(() => {
//   //   Aos.init({ duration: 2000 })
//   // }, [])

//   const [selected, setSelected] = useState('Economy');

//   const handleSelect = (classType) => {
//     if (['Economy', 'Business Class', 'First Class'].includes(classType)) {
//       setSelected(classType);
//     } else {
//       console.error('Invalid class type');
//     }
//   };

//   return (
//     <div className="search section container">
//       <div
//       //  data-aos='fade-up' 
//       //  data-aos-duration='2500' 
//        className="sectionContainer">

//         {/* <div className="btns flex">
//           <div className="singleBtn">
//             <span>Economy</span>
//           </div>
//           <div className="singleBtn">
//             <span>Business Class</span>
//           </div>
//           <div className="singleBtn">
//             <span>First Class</span>
//           </div>
//         </div> */}
// <div className="btns flex">
//       <div
//         className={`singleBtn ${selected === 'Economy' ? 'select' : ''}`}
//         onClick={() => handleSelect('Economy')}
//       >
//         <span>Economy</span>
//       </div>
//       <div
//         className={`singleBtn ${selected === 'Business Class' ? 'select' : ''}`}
//         onClick={() => handleSelect('Business Class')}
//       >
//         <span> Business Class</span>
//       </div>
//       <div
//         className={`singleBtn ${selected === 'First Class' ? 'select' : ''}`}
//         onClick={() => handleSelect('First Class')}
//       >
//         <span>First Class</span>
//       </div>


//     </div>



//         <form>
//         <div data-aos='fade-up' data-aos-duration='2000' className="searchInputs flex">
//           <div className="singleInput flex">
//             <div className="iconDiv">
//               <HiOutlineLocationMarker className="icon" />
//             </div>
//             <div className="texts">
//               <h4>Location</h4>
//               <input type="text" placeholder='Where would you like to go?' />
//             </div>
//           </div>
//           <div className="singleInput flex">
//             <div className="iconDiv">
//               <RiAccountPinCircleLine className='icon' />
//             </div>
//             <div className="texts">
//               <h4>Travelers</h4>
//               <input type="text" placeholder='Add guests' />
//             </div>
//           </div>

//           <div className="singleInput flex">
//             <div className="iconDiv">
//               <RxCalendar className='icon' />
//             </div>
//             <div className="texts">
//               <h4>Check In</h4>
//               <input type="text" placeholder='Add date' />
//             </div>
//           </div>
//           <div className="singleInput flex">
//             <div className="iconDiv">
//               <RxCalendar className='icon' />
//             </div>
//             <div className="texts">
//               <h4>Check Out</h4>
//               <input type="text" placeholder='Add date' />
//             </div>
//           </div>
//           <button type='submit' className='btn btnBlock flex'>Search Flights</button>
//         </div>
//         </form>
//       </div>
//     </div>
//   )
// }

// export default Search



// import FlightIcon from '@mui/icons-material/Flight';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
// import { useRef, useState } from 'react';
// import Book from '../booking/book'
// import Check from '../booking/check'
// import Status from '../booking/status'
// import Trip from '../booking/trip'
// import { useEffect } from 'react';
// import './Search.css'



// const Search = () => {
// const book = useRef(null)
// const check = useRef(null)
// const trip = useRef(null)
// const flight = useRef(null)

//  const [isbook, setisBook]= useState(true)
// const [ischeck, setisCheck]= useState(false)
// const [isstatus, setisStatus]= useState(false)
// const [istrip, setisTrip]= useState(false)

// useEffect(()=>{
// const book_ele = book.current;
//     book_ele.classList.add('active_booking_button')
// },[])

// const activeBooking = (e)=>{

// const book_ele = book.current;
// const flight_ele = flight.current;
// const check_ele = check.current;
// const trip_ele = trip.current;

//         const ele = e.currentTarget;
//         const text = e.currentTarget.textContent;
//         ele.classList.add('active_booking_button')
//         if(text ==='Book'){
//             flight_ele.classList.remove('active_booking_button')
//             check_ele.classList.remove('active_booking_button')
//             trip_ele.classList.remove('active_booking_button')
//             //book_ele.classList.remove('active_booking_button')
//             // --------------------------------------
//             setisBook(true);setisTrip(false);setisCheck(false);setisStatus(false)
//         }else
// if(text ==='My Trips'){
//             flight_ele.classList.remove('active_booking_button')
//             check_ele.classList.remove('active_booking_button')
//             //trip_ele.classList.remove('active_booking_button')
//             book_ele.classList.remove('active_booking_button')
//         // --------------------------
//     setisBook(false);setisTrip(true);setisCheck(false);setisStatus(false)}else
// if(text ==='Check-in'){
//     flight_ele.classList.remove('active_booking_button')
//             //check_ele.classList.remove('active_booking_button')
//             trip_ele.classList.remove('active_booking_button')
//             book_ele.classList.remove('active_booking_button')
//             // ---------------------------------------------
//             setisBook(false);setisTrip(false);setisCheck(true);setisStatus(false)
// }else
// if(text ==='Flight status'){
//     //flight_ele.classList.remove('active_booking_button')
//             check_ele.classList.remove('active_booking_button')
//             trip_ele.classList.remove('active_booking_button')
//             book_ele.classList.remove('active_booking_button')
//             // ----------------------------------------------
//             setisBook(false);setisTrip(false);setisCheck(false);setisStatus(true)
// }
//     }

// return(
//     <section className='booking_cont '>
//         <article className="qatar_booking">
//        <div className="booking_nav sm:flex flex-col">
//         {/* -------------------- */}
//         <div className="booking_button" onClick={activeBooking}ref={book}><FlightIcon/>Book</div>
//         <div className="booking_button" onClick={activeBooking}ref={trip}><DateRangeIcon/>My Trips</div>
//         <div className="booking_button" onClick={activeBooking}ref={check}><CheckCircleOutlineIcon/>Check-in</div>
//         <div className="booking_button" onClick={activeBooking}ref={flight}><AirlineSeatReclineExtraIcon/>Flight status</div>
//         {/* -------------------- */}
//        </div>

//        <div className="booking_cont">
//         {isbook&&<Book/>}
//         {istrip&&<Trip/>}
//         {ischeck&&<Check/>}
//         {isstatus&&<Status/>}
//         </div>      
//         </article>

//         {/* ------------------------------------------- */}

// {/* <article className="qatar_ads">   </article> */}
        
//     </section>
// )

// }

// export default Search;
import React, { useContext, useState } from 'react';
import { PrivateRouteContext } from '../Context/PrivateRouteContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

const LoginFormHotel = () => {
const { infos } = useContext(PrivateRouteContext)
const [password, setPassword] = useState('');
const [username, setUserName] = useState('');
const [isPasswordVisible, setIsPasswordVisible] = useState(false);
const navigate = useNavigate();
const location = useLocation();
const from = location.state?.from?.pathname || '/oscrohdashboard';


const handlePasswordChange = (e) => {
    setPassword(e.target.value);
};

const handleUserNameChange = (e) => {
    setUserName(e.target.value);
};

const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);   
};


const checkLoginDetails = (username, password)=> {
  let checkUsername = infos.filter((info, idx) => {
    return info.username === username;
  });

  let checkPassword = infos.filter((info, idx) => {
    return info.password === password;
  });
  


  if(checkUsername.length > 0 && checkPassword.length > 0) {
    sessionStorage.setItem('authenticated', 'true');
    console.log(sessionStorage.getItem('authenticated'))
      navigate(from, { replace: true });
    } else {
      alert('Invalid credentials');
    }

}


const getLoginDetails = (e) => {
  e.preventDefault();
  if(e.target[0].value !== '' && e.target[1].value !== '') {
    checkLoginDetails(e.target[0].value, e.target[1].value, e.target[2].value);
    }

  }


return (
<div className="min-h-screen flex items-center justify-center bg-blue-700">
<div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">


<form onSubmit={getLoginDetails}>
    <div className="mb-4">
    <label className="block text-zinc-700 text-sm font-bold mb-2" htmlFor="username">
        Username
    </label>
    <div className="flex items-center border border-zinc-300 rounded-lg relative">
        <input
        type="text"
        id="username"
        value={username}
        placeholder="Username"
        onChange={handleUserNameChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
    </div>
    </div>

    <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <div className="flex items-center border border-zinc-300 rounded-lg relative">
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              id="password"
              value={password}
              placeholder="Password"
              onChange={handlePasswordChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
              {isPasswordVisible ? (
               <FaRegEye />
              ) : (
                <FaRegEyeSlash />
              )}
            </button>
          </div>
        </div>


    <div className="flex items-center justify-between">
    <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
        Log In
    </button>
    </div>


</form>
</div>
</div>
);
};

export default LoginFormHotel;




// import React, { useState } from 'react';

// const LoginForm = () => {
// const [password, setPassword] = useState('');
// const [isPasswordVisible, setIsPasswordVisible] = useState(false);

// const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
// };

// const togglePasswordVisibility = () => {
//     setIsPasswordVisible(!isPasswordVisible);
// };


// return (
// <div className="min-h-screen flex items-center justify-center bg-blue-700">
// <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
// <form>
//     <div className="mb-4">
//     <label className="block text-zinc-700 text-sm font-bold mb-2" htmlFor="username">
//         Username
//     </label>
//     <div className="flex items-center border border-zinc-300 rounded-lg">
//         <span className="px-3 text-zinc-500">
//         <i className="fas fa-user"></i>
//         </span>
//         <input
//         type="text"
//         id="username"
//         placeholder="Username"
//         className="w-full py-2 px-3 text-zinc-700 leading-tight focus:outline-none focus:shadow-outline"
//         />
//     </div>
//     </div>
//     <div className="mb-6">
//     <label className="block text-zinc-700 text-sm font-bold mb-2" htmlFor="password">
//         Password
//     </label>
//     <div className="flex items-center border border-zinc-300 rounded-lg">
//         <span className="px-3 text-zinc-500">
//         <i className="fas fa-lock"></i>
//         </span>
//         <input
//         value={password}
//         id="password"
//         type={isPasswordVisible ? 'text' : 'password'}
//         placeholder="Password"
//         className="w-full py-2 px-3 text-zinc-700 leading-tight focus:outline-none focus:shadow-outline"
//         I
//         />
//     </div>
//     </div>
//     <div className="flex items-center justify-between">
//     <button
//         type="submit"
//         className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//     >
//         Log In
//     </button>
//     </div>
// </form>
// </div>
// </div>
// );
// };

// export default LoginForm;
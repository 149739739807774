import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './../Navbar/Navbar';
import Footer from './../Footer/Footer';
import '../../main.scss'
// import DashboardProvider from '../Context/DashboardContext';


const AppLayout = () => {
  return (
    <div>
        
        <Navbar />
           <main>
           <Outlet />
           </main>

           <Footer />
       
    </div>
  )
}

export default AppLayout
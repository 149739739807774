import React, { useState, useEffect, useRef, useContext  } from 'react';
// import { HotelDashboardContext } from '../Context/HotelDashboardContext';
import { CiCalendarDate } from "react-icons/ci";
import { TbListDetails } from "react-icons/tb";
import { IoLocationOutline } from "react-icons/io5";
import { HotelDashboardContext } from '../Context/HotelDashboardContext';

// import {db} from '../../firebase-config'
// import {collection, getDocs, addDoc, updateDoc, doc, deleteDoc} from 'firebase/firestore'
// import { useNavigate } from 'react-router-dom';
// import html2canvas from 'html2canvas';

const HotelDashboard = () => {
  const {
      infos,
      loading,
      send,
      handleEdit,
      deleteInfo,
      handleDownload,
      handlePreview,
      closeModal,
      createModal,
      closeCreateModal,
      isModalOpen,
      setIsModalOpen,
      isModalOpen2,
      setIsModalOpen2,
      previewData,
      htmlRef,} = useContext(HotelDashboardContext)
  // const [isOpen, setIsOpen] = useState(false);
  // const [infos, setInfos] = useState([]);
  // const infosCollectionRef = collection(db, "flight_infos");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalOpen2, setIsModalOpen2] = useState(false);
  // const [previewData, setPreviewData] = useState({});
  // const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  // const htmlRef = useRef(null);
  const [editAddress, setEditAddress] = useState('');
  const [editAdults, setEditAdults] = useState('');
  const [editCheckInDate, setEditCheckInDate] = useState('');
  const [editCheckInTime, setEditCheckInTime] = useState('');
  const [editCheckOutDate, setEditCheckOutDate] = useState('');
  const [editCheckOutTime, setEditCheckOutTime] = useState('');
  const [editPinCode, setEditPinCode] = useState('')
  const [editDestination, setEditDestination] = useState('');
  const [editNights, setEditNights] = useState('');
  const [editRooms, setEditRooms] = useState('');
  const [editFirstName, setEditFirstName] = useState('');
  const [editSurname, setEditSurname] = useState('');
  const [editEmail, setEditEmail] = useState('');
 


  return (
    <>
      <div className="flex">
        <div className="  p-10 texts font-bold">
          <div class="mb-4">
        <input type="text" placeholder="Search Hotel Bookings" class="w-full p-2 border rounded-lg" />
      </div>
          <div><div className="main_dashboard p-3">
            <div className="main_content my-3">
              <button className='btn btn-primary' onClick={createModal}>Create Booking </button>
              <div className="header_money mb-4 d-flex align-items-center justify-content-between">
                {/* <Sidebar /> */}
                <h1>All Hotel Bookings </h1>
              </div>

              <ol className="list_tick">
                {!loading && infos.map((info, idx)=> (                
                  <li className="mb-4" key={idx}> 
                    <div className="ticketList flex flex-wrap align-items-center">
                      <h6>{info.destination}</h6>
                      <button className="btn ms-4 btn-primary text-white px-4 py-2 rounded-lg me-3" onClick={()=> handlePreview(info)}>preview</button>
                      <button className="btn btn-primary text-white px-4 py-2 rounded-lg me-3" onClick={()=> handleDownload(info)}>download png</button>
                      <button className="bg-red-500 text-white px-4 py-2 rounded-lg" onClick={()=> deleteInfo(info)}>delete</button>
                    </div>
                  </li>
                ))}
                {loading && <li>loading</li>}
              </ol>
            </div>
          </div></div>
        </div>

      </div>


      {/* preview modal */}
      {isModalOpen && (
        <div className="p-4 bg-zinc-100 inset-0">
        <div className="max-w-2xl mx-auto  rounded-lg shadow-md p-8" ref={htmlRef}>
        {/* <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md"> */}
          <h1 className="text-2xl font-bold text-blue-600 dark:text-blue-400">{previewData.destination}</h1>
          <div className="flex justify-between mt-4">
            <div className=" items-start">
            <div className='flex items-center'>
                <CiCalendarDate />
                <p className="text-black">Check-in</p>
                </div>
              <div>
                <p className="font-semibold">{previewData.checkInDate}</p>
                <p className="text-zinc-600">{previewData.checkInTime}</p>
              </div>
            </div>
            <div className="flex items-start">
              {/* <img src="https://placehold.co/24x24" alt="calendar" className="mr-2"> */}
              <div>
                <p className="text-black">Check-out</p>
                <p className="font-semibold">{previewData.checkOutDate}</p>
                <p className="text-zinc-600">{previewData.checkOutTime}</p>
              </div>
            </div>
          </div>
          <div className="mt-4 p-2 bg-green-100 dark:bg-green-900 rounded-md">
            <p className="text-zinc-600 dark:text-zinc-300">Confirmation number: <span className="font-semibold text-black dark:text-white">{previewData.confirmNumber}</span></p>
            <p className="text-zinc-600 dark:text-zinc-300">PIN code: <span className="font-semibold text-black dark:text-white">{previewData.code}</span></p>
          </div>
          <div className="">
            <div className='flex items-center'>
              <div><TbListDetails /></div>
              <div> <p className="font-semibold text-black">Booking details</p></div>
            </div>
          
            <div>
              <p className="text-zinc-600">{previewData.adults} adults - {previewData.nights} nights, {previewData.rooms} room</p>
            </div>
          </div>
          <div className="items-start mt-4">
          <div className='flex items-center'>
          <IoLocationOutline />
              <p className="font-semibold text-black">Address</p>
          </div>
            <div>
              <p className="text-zinc-600">{previewData.address}</p>
            </div>
          </div>
        {/* </div> */}
        
        
        </div>

          <div className="flex justify-end p-2 mt-10">
          <button className='px-4 py-2  btn' onClick={()=> {
            setEdit(true); setIsModalOpen2(true); setIsModalOpen(false);
            setEditAddress(previewData.address);
            setEditAdults(previewData.adults);
            setEditCheckInDate(previewData.checkInDate);
            setEditCheckInTime(previewData.checkInTime)
            setEditCheckOutDate(previewData.checkOutDate);
            setEditCheckOutTime(previewData.checkOutTime)
            setEditPinCode(previewData.code);
            setEditDestination(previewData.destination)
            setEditNights(previewData.nights);
            setEditRooms(previewData.rooms);
            setEditFirstName(previewData.firstName);
            setEditSurname(previewData.surName);
            setEditEmail(previewData.email)
            }}>edit</button>
          <button onClick={closeModal} className="px-4 py-2 font-semibold text-white bg-red-500 rounded hover:bg-red-700">
          Close
          </button>
         </div>


      </div>
      )}




      
      {/* model */}
      {isModalOpen2 && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-1/3 max-h-full overflow-y-auto">
        {/* <div className="px-4 py-2 text-lg font-semibold border-b">Modal Header</div> */}
        <div className="p-4">
        <form action=""  onSubmit={(e)=> edit? handleEdit(e, previewData) : send(e)}>
                <div>
                  <label htmlFor="">destination</label>
                  <input className='border' value={editDestination} onChange={(e)=> setEditDestination(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">check-in Date</label>
                  <input className='border' value={editCheckInDate} onChange={(e)=> setEditCheckInDate(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">check-in time</label>
                  <input className='border' value={editCheckInTime} onChange={(e)=> setEditCheckInTime(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">check-out Date</label>
                  <input className='border' value={editCheckOutDate} onChange={(e)=> setEditCheckOutDate(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">check-out time</label>
                  <input className='border' value={editCheckOutTime} onChange={(e)=> setEditCheckOutTime(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">pin-code</label>
                  <input className='border' value={editPinCode} onChange={(e)=> setEditPinCode(e.target.value)} type="number" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">adults</label>
                  <input className='border' value={editAdults} onChange={(e)=> setEditAdults(e.target.value)} type="number" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">nights</label>
                  <input className='border'value={editNights} onChange={(e)=> setEditNights(e.target.value)} type="number" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">rooms</label>
                  <input className='border' value={editRooms} onChange={(e)=> setEditRooms(e.target.value)} type="number" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">Address</label>
                  <input className='border' value={editAddress} onChange={(e)=> setEditAddress(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">firstname</label>
                  <input className='border' value={editFirstName} onChange={(e)=> setEditFirstName(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">surname</label>
                  <input className='border' value={editSurname} onChange={(e)=> setEditSurname(e.target.value)} type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">email</label>
                  <input className='border' value={editEmail} onChange={(e)=> setEditEmail(e.target.value)} type="text" name="" id="" />
                </div>
                
               
                <button className='btn btn-primary'>send</button>
              </form>
        </div>
        <div className="flex justify-end p-2">
          <button onClick={closeCreateModal} className="px-4 py-2 font-semibold text-white bg-red-500 rounded hover:bg-red-700">
            Close
          </button>
        </div>
      </div>
    </div>
      )}
    </>
  );
};


export default HotelDashboard;




import React from 'react'
import notFoundImg from '../../assets/no-result-flight-search.png'

const Notfound = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4 pt-[200px]">
          <div className="bg-white p-6 text-center max-w-lg w-full">
            <img src={notFoundImg} alt="" className="mb-4 mx-auto" />
            <h2 className="text-2xl font-bold text-zinc-800 mb-2">We couldn't find your Hotel.</h2>
            <p className="text-zinc-600 mb-4">             
              We've searched, and couldn't find any available hotels
            </p>
          </div>
        </div>
    )
}

export default Notfound
import React from "react";
import { FaPlaneArrival, FaPlaneDeparture, FaChild } from "react-icons/fa";
import { GiPerson } from "react-icons/gi";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const Hotel = () => {
  const navigate = useNavigate();
  // handle event
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // handle submit
  const onSubmit =  () => {
    // event.preventDefault();
    // Here you can add any form validation or submission logic
    navigate('/notFound');
  };

  
  return (
    <React.Fragment>
      <section className="bgcustomsec">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" w-auto h-auto pb-10 mt-5 mx-5 px-5 rounded-lg sm:w-full md:mx-auto">
      

            {/* body section */}
            <div>
              <div className="grid justify-center space-y-5 pb-10">
                <div>
                  <div className="flex space-x-8 mt-5">
                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        className={`w-4 h-4 ${
                          errors.tripType &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        value="round trip"
                        {...register("tripType", {
                          required: {
                            value: true,
                            message: "Reason for travelling is required",
                          },
                        })}
                      />
                      <p className="texts">Bussiness</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        className={`w-4 h-4 ${
                          errors.tripType &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        value="one way"
                        {...register("tripType", {
                          required: {
                            value: true,
                            message: "Reason for travelling is required",
                          },
                        })}
                      />
                      <p className="texts">Leisure</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        className={`w-4 h-4 ${
                          errors.tripType &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        value="multy-city"
                        {...register("tripType", {
                          required: {
                            value: true,
                            message: "Reason for travelling is required",
                          },
                        })}
                      />
                      <p className="texts">Tourist</p>
                    </div>
                  </div>
                  <div>
                    {errors.tripType && (
                      <span className="text-sm text-red-500">
                        {errors.tripType.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* departure section */}
                <div>
                  <div>
                    <div className="relative">
                      <p className="texts">Going to</p>
                      <input
                      placeholder="Destination, city or hotel name"
                        className={`w-full h-16 texts rounded-lg ${
                          errors.departure &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        {...register("departure", {
                          required: {
                            value: true,
                            message: "Destination, city or hotel name is required",
                          },
                        })}
                    />
                    </div>
                    <div>
                      {errors.departure && (
                        <span className="text-sm text-red-500">
                          {errors.departure.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>







                {/* date section */}
                <div className="flex space-x-2 w-full">
                  {/* departure section */}
                  <div className="w-full">
                    <div className="w-full">
                      <div className="relative">
                        <p className="texts">
                          Check-In
                        </p>
                        <input
                          type="date"
                          className={`w-full h-16 texts rounded-lg ${errors.departureDate &&
                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                          {...register("departureDate", {
                            required: {
                              value: true,
                              message: "Check-in date is required",
                            },
                          })}
                        />
                      </div>
                      <div>
                      {errors.departureDate && (
                        <span className="text-sm text-red-500">
                          {errors.departureDate.message}
                        </span>
                      )}
                      </div>
                    </div>
                  </div>

                  {/* return section */}
                  <div className="w-full">
                    <div>
                      <div className="relative">
                        <p className="texts">
                          Check-Out
                        </p>
                        <input
                          type="date"
                          className={`w-full h-16 texts rounded-lg ${errors.returnDate &&
                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                          {...register("returnDate", {
                            required: {
                              value: true,
                              message: "Check-out date is required",
                            },
                          })}
                        />
                      </div>
                      <div>
                      {errors.returnDate && (
                        <span className="text-sm text-red-500">
                          {errors.returnDate.message}
                        </span>
                      )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* passenger section */}
                <div className="flex space-x-2">
                  {/* adult section */}
                  <div className="w-full">
                    <div>
                      <div className="relative">
                        <p className="texts">
                          {" "}
                          Adults (18+)
                        </p>
                        <select 
                        className="w-full h-16 rounded-lg texts pl-20"
                        {...register("adult", {
                            required: {
                              value: true,
                              message: "Trip type is required",
                            },
                          })}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                        <GiPerson className="text-4xl absolute left-5 top-10 " />
                      </div>
                      {/* <div>Error</div> */}
                    </div>
                  </div>

                  {/* children section */}
                  <div className="w-full">
                    <div>
                      <div className="relative">
                        <p className="texts">
                          {" "}
                          Children (0-17)
                        </p>
                        <select 
                        className="w-full h-16 rounded-lg texts pl-20"
                        {...register("children", {
                            required: {
                              value: true,
                              message: "Trip type is required",
                            },
                          })}
                        >
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                        <FaChild className="text-4xl absolute left-5 top-10 " />
                      </div>
                      {/* <div>Error</div> */}
                    </div>
                  </div>
                </div>

                
                {/* btn section */}
                <div>
                  <input
                    type="submit"
                    value="Find Hotel"
                    className="w-full btn btnBlock"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </React.Fragment>
  );
};

export default Hotel;

// import { useState } from 'react'
// import { SiConsul } from 'react-icons/si'
// import { AiOutlineGlobal } from 'react-icons/ai'
// import { BsPhoneVibrate } from 'react-icons/bs'
// import { CgMenuGridO } from 'react-icons/cg'
// import logo from '../../assets/logo.png'

// const Navbar = () => {

//   // remove navBar on small screens
//   const [active, setActive] = useState('navBarMenu');


//   const showNavBar = () => {
//     setActive('navBarMenu showNavBar');
//   }
//   const removeNavBar = () => {
//     setActive('navBarMenu');
//   }

//   // add bgcolor on second nabVar
//   const [noBg, addBg] = useState('navBarTwo');
//   const addBgColor = () => {
//     if (window.scrollY >= 10) {
//       addBg('navBarTwo navbar_With_Bg');
//     } else {
//       addBg('navBarTwo')
//     }
//   }
//   window.addEventListener('scroll', addBgColor)

//   return (
//     <div className='navBar flex'>
//       <div className="navBarOne flex">
//         <div>
//           <SiConsul className='icon' />
//         </div>

//         <div className="none flex">
//           <li className='flex'><BsPhoneVibrate className='icon' />Support</li>
//           <li className='flex'><AiOutlineGlobal className='icon' />Languages</li>
//         </div>

//         <div className="atb flex">
//           <span>Sign In</span>
//           <span>Sign Out</span>
//         </div>

//       </div>

//       <div className={noBg}>
//         <div className="logoDiv">
//           <img src={logo} className='logo' alt="logo" />
//         </div>
//         <div className={active}>
//           <ul className="menu flex">
//             <li onClick={removeNavBar} className="listItem">Home</li>
//             <li onClick={removeNavBar} className="listItem">About</li>
//             <li onClick={removeNavBar} className="listItem">Offers</li>
//             <li onClick={removeNavBar} className="listItem">Hotels</li>
//             <li onClick={removeNavBar} className="listItem">Manage Bookings</li>
//           </ul>
//           <button className='btn flex btnOne'>Contact</button>


//         </div>
//         <button className='btn flex btnTwo'>Contact</button>
//         <div onClick={showNavBar} active={active} className="toggleIcon">
//           <CgMenuGridO className='icon' />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Navbar


import { useState, useEffect } from 'react';
import { SiConsul } from 'react-icons/si';
import { AiOutlineGlobal } from 'react-icons/ai';
import { BsPhoneVibrate } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import logo from '../../assets/logo.png';

const Navbar = () => {
  const [isNavBarVisible, setIsNavBarVisible] = useState(false);
  const [navBarClass, setNavBarClass] = useState('navBarTwo');

  const toggleNavBar = () => {
    setIsNavBarVisible((prevState) => !prevState);
  };

  const updateNavBarClass = () => {
    if (window.scrollY >= 10) {
      setNavBarClass('navBarTwo navbar_With_Bg');
    } else {
      setNavBarClass('navBarTwo');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updateNavBarClass);
    return () => {
      window.removeEventListener('scroll', updateNavBarClass);
    };
  }, []);

  return (
    <div className='navBar flex'>
      <div className="navBarOne flex">
        <div>
          <SiConsul className='icon' />
        </div>
        <div className="none flex">
          <a href="/contactpage">  <li className='flex'><BsPhoneVibrate className='icon' />Support</li></a>
          <li className='flex'><AiOutlineGlobal className='icon' />Languages</li>
        </div>
        <div className="atb flex">
          <span>Sign In</span>
          <span>Sign Out</span>
        </div>
      </div>

      <div className={navBarClass}>
        <div className="logoDiv">
          <a href="/"><img src={logo} className='logo' alt="logo" /></a> 
        </div>
        <div className={`navBarMenu ${isNavBarVisible ? 'showNavBar' : ''}`}>
          <ul className="menu flex">
            <li onClick={toggleNavBar} className="listItem"><a href='/'>Home</a></li>
            <li onClick={toggleNavBar} className="listItem">Offers</li>
            <li onClick={toggleNavBar} className="listItem"><a href="/hotels">Hotels</a></li>
            <li onClick={toggleNavBar} className="listItem"><a href="/managebooking">Manage Booking</a></li>
          </ul>
          <button className='btn flex btnOne'>Contact</button>
        </div>
        <button className='btn flex btnTwo'><a href='/contactpage'>Contact</a></button>
        <div onClick={toggleNavBar} className="toggleIcon">
          <CgMenuGridO className='icon' />
        </div>
      </div>
    </div>
  );
};

export default Navbar;


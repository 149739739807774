import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from '@firebase/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyDKrauJDhmmD4MHPcebRDQ70tb_pIXNNgw",
  authDomain: "flight-392e6.firebaseapp.com",
  projectId: "flight-392e6",
  storageBucket: "flight-392e6.appspot.com",
  messagingSenderId: "808758501756",
  appId: "1:808758501756:web:5f5d76af538c702de684f9",
  measurementId: "G-1EHMSQE2LZ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);



import { useEffect } from "react"
import supportimg from "../../assets/supporting.png"
// import Aos from 'aos'
// import 'aos/dist/aos.css'


function Support() {

  // useEffect(() => {
  //   Aos.init({ duration: 2000 })
  // }, [])

  return (
    <div className="support container section">
      <div className="sectionContainer">

        <div className="titlesDiv">
          <small>Travel Support</small>
          <h2>Find help with Booking</h2>
          <p>Need assistance with your travel plans? Our dedicated support team is here to help you every step of the way. Whether you’re booking a flight, changing your itinerary, or have any questions, we’ve got you covered. </p>
        </div>

        <div className="infoDiv grid">
          <div className="textDiv grid">

            <div 
            // data-aos='fade-down' 
            // data-aos-duration='2500' 
            className="singleInfo">
              <span className="number">01</span>
              <h4>Travel requirments for USA</h4>
              <p>Planning a trip to the USA? Get detailed information on visa requirements, customs regulations, and essential travel tips to ensure a smooth journey.</p>
            </div>

            <div data-aos='fade-down' data-aos-duration='3500' className="singleInfo">
              <span className="number colorOne">02</span>
              <h4>Travel Insurance</h4>
              <p>Protect your trip with comprehensive travel insurance. Learn about the coverage options available to safeguard your travel plans against unexpected events.</p>
            </div>

            <div data-aos='fade-down' data-aos-duration='4500' className="singleInfo">
              <span className="number colorTwo">03</span>
              <h4>Services at your arrival</h4>
              <p>Discover the array of services available to you upon arrival, from airport transfers to local guides and more. Make the most of your destination with our tailored arrival services.</p>
            </div>

          </div>

          <div className="imgDiv">
            <img data-aos='fade-left' data-aos-duration='4500' src={supportimg} alt="" />
          </div>

        </div>
      </div>
    </div>
  )
}

export default Support

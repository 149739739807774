// import React, { useState } from 'react'
// import { HiOutlineLocationMarker } from 'react-icons/hi';
// import { RiAccountPinCircleLine } from 'react-icons/ri';
// import { RxCalendar } from 'react-icons/rx';



// const Book = () => {
//     const [selected, setSelected] = useState('Economy');

//     const handleSelect = (classType) => {
//       setSelected(classType);
//     };
  
//  // handle submit
//  const handleSubmit = (data) => alert(JSON.stringify(data));

//   return (
    
//     <div className="sectionContainer">
//     <form onSubmit={handleSubmit}>
//     <div className="btns flex">
//       {['Economy', 'Business Class', 'First Class'].map((classType) => (
//         <div
//           key={classType}
//           className={`singleBtn ${selected === classType ? 'select' : ''}`}
//           onClick={() => handleSelect(classType)}
//         >
//           <span>{classType}</span>
//         </div>
//       ))}
//     </div>

//       <div className="searchInputs flex">
//         <div className="singleInput flex">
//           <div className="iconDiv">
//             <HiOutlineLocationMarker className="icon" />
//           </div>
//           <div className="texts">
//             <h4>Location</h4>
//             <input type="text" placeholder="Where would you like to go?" />
//           </div>
//         </div>
//         <div className="singleInput flex">
//           <div className="iconDiv">
//             <RiAccountPinCircleLine className="icon" />
//           </div>
//           <div className="texts">
//             <h4>Travelers</h4>
//             <input type="text" placeholder="Add guests" />
//           </div>
//         </div>
//         <div className="singleInput flex">
//           <div className="iconDiv">
//             <RxCalendar className="icon" />
//           </div>
//           <div className="texts">
//             <h4>Check In</h4>
//             <input type="text" placeholder="Add date" />
//           </div>
//         </div>
//         <div className="singleInput flex">
//           <div className="iconDiv">
//             <RxCalendar className="icon" />
//           </div>
//           <div className="texts">
//             <h4>Check Out</h4>
//             <input type="text" placeholder="Add date" />
//           </div>
//         </div>
//         <button type="submit" className="btn btnBlock flex">
//           Search Flights
//         </button>
//       </div>
//     </form>
//   </div>
//   )
// }

// export default Book






import React from "react";
import { FaPlaneArrival, FaPlaneDeparture, FaChild } from "react-icons/fa";
import { GiPerson } from "react-icons/gi";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const Book = () => {
  const navigate = useNavigate();
  // handle event
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // handle submit
  const onSubmit =  () => {
    // event.preventDefault();
    // Here you can add any form validation or submission logic
    navigate('/notFoundPage');
  };






  // // handle event
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm();

  // // handle submit
  // const onSubmit = (data) => alert(JSON.stringify(data));
  return (
    <React.Fragment>
      <section className="bgcustomsec">
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" w-auto h-auto pb-10 mt-5 mx-5 px-5 rounded-lg sm:w-full md:mx-auto">
      

            {/* body section */}
            <div>
              <div className="grid justify-center space-y-5 pb-10">
                <div>
                  <div className="flex space-x-8 mt-5">
                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        className={`w-4 h-4 ${
                          errors.tripType &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        value="round trip"
                        {...register("tripType", {
                          required: {
                            value: true,
                            message: "Trip type is required",
                          },
                        })}
                      />
                      <p className="texts">Round trip</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        className={`w-4 h-4 ${
                          errors.tripType &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        value="one way"
                        {...register("tripType", {
                          required: {
                            value: true,
                            message: "Trip type is required",
                          },
                        })}
                      />
                      <p className="texts">One way</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="radio"
                        className={`w-4 h-4 ${
                          errors.tripType &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        value="multy-city"
                        {...register("tripType", {
                          required: {
                            value: true,
                            message: "Trip type is required",
                          },
                        })}
                      />
                      <p className="texts">Multy-city</p>
                    </div>
                  </div>
                  <div>
                    {errors.tripType && (
                      <span className="text-sm text-red-500">
                        {errors.tripType.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* departure section */}
                <div>
                  <div>
                    <div className="relative">
                      <p className="texts uppercase">flying from</p>
                      <select
                        className={`w-full h-16 texts pl-20 rounded-lg ${
                          errors.departure &&
                          " focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        {...register("departure", {
                          required: {
                            value: true,
                            message: "Departure is required",
                          },
                        })}
                      >
                        <option value="" selected disabled hidden>
                          --Select Airport--
                        </option>
                        <option value="ENIA">
                          {" "}
                          England Newcastle International Airport
                        </option>
                        <option value="INIA">
                          {" "}
                          Italy Naples International Airport
                        </option>
                        <option value="MMA"> Malaysia Mulu Airport</option>
                        <option value="KMA"> Kenya Malindi Airport</option>
                      </select>
                      <FaPlaneDeparture className="text-4xl absolute left-5 top-10 " />
                    </div>
                    <div>
                      {errors.departure && (
                        <span className="text-sm text-red-500">
                          {errors.departure.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/* arrival section */}
                <div>
                  <div>
                    <div className="relative">
                      <p className=" texts uppercase">flying to</p>
                      <select 
                      className={`w-full h-16 texts pl-20 rounded-lg ${
                        errors.arrival &&
                        " focus:border-red-500 focus:ring-red-500 border-red-500"
                      }`}
                      {...register("arrival", {
                        required: {
                          value: true,
                          message: "Arrival is required",
                        },
                      })}
                      >
                        <option value="" selected disabled hidden>
                          --Select Airport--
                        </option>
                        <option value="ENIA">
                          {" "}
                          England Newcastle International Airport
                        </option>
                        <option value="INIA">
                          {" "}
                          Italy Naples International Airport
                        </option>
                        <option value="MMA"> Malaysia Mulu Airport</option>
                        <option value="KMA"> Kenya Malindi Airport</option>
                      </select>
                      <FaPlaneArrival className="text-4xl absolute left-5 top-10 " />
                    </div>
                    <div>
                    {errors.arrival && (
                        <span className="text-sm text-red-500">
                          {errors.arrival.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/* date section */}
                <div className="flex space-x-2">
                  {/* departure section */}
                  <div className="w-full">
                    <div >
                      <div className="relative">
                        <p className="texts uppercase">
                          departure date
                        </p>
                        <input
                          type="date"
                          className={`w-full h-16 texts rounded-lg ${errors.departureDate &&
                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                          {...register("departureDate", {
                            required: {
                              value: true,
                              message: "Departure date is required",
                            },
                          })}
                        />
                      </div>
                      <div>
                      {errors.departureDate && (
                        <span className="text-sm text-red-500">
                          {errors.departureDate.message}
                        </span>
                      )}
                      </div>
                    </div>
                  </div>

                  {/* return section */}
                  <div className="w-full">
                    <div>
                      <div className="relative">
                        <p className="texts uppercase">
                          return date
                        </p>
                        <input
                          type="date"
                          className={`w-full h-16 texts rounded-lg ${errors.returnDate &&
                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                          {...register("returnDate", {
                            required: {
                              value: true,
                              message: "Return date is required",
                            },
                          })}
                        />
                      </div>
                      <div>
                      {errors.returnDate && (
                        <span className="text-sm text-red-500">
                          {errors.returnDate.message}
                        </span>
                      )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* passenger section */}
                <div className="flex space-x-2">
                  {/* adult section */}
                  <div className="w-full">
                    <div>
                      <div className="relative">
                        <p className="texts uppercase">
                          {" "}
                          adults (18+)
                        </p>
                        <select 
                        className="w-full h-16 rounded-lg texts pl-20"
                        {...register("adult", {
                            required: {
                              value: true,
                              message: "Trip type is required",
                            },
                          })}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                        <GiPerson className="text-4xl absolute left-5 top-10 " />
                      </div>
                      {/* <div>Error</div> */}
                    </div>
                  </div>

                  {/* children section */}
                  <div className="w-full">
                    <div>
                      <div className="relative">
                        <p className="texts uppercase">
                          {" "}
                          children (0-17)
                        </p>
                        <select 
                        className="w-full h-16 rounded-lg texts pl-20"
                        {...register("children", {
                            required: {
                              value: true,
                              message: "Trip type is required",
                            },
                          })}
                        >
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                        <FaChild className="text-4xl absolute left-5 top-10 " />
                      </div>
                      {/* <div>Error</div> */}
                    </div>
                  </div>
                </div>

                {/* class and price section */}
                <div className="flex space-x-2">
                  {/* class section */}
                  <div className="w-full">
                    <div>
                      <div>
                        <p className="texts uppercase">class</p>
                        <select
                         className="w-full h-16 rounded-lg texts"
                         {...register("class", {
                            required: {
                              value: true,
                              message: "Trip type is required",
                            },
                          })}
                         >
                          <option>Economy</option>
                          <option>Business</option>
                        </select>
                      </div>
                      {/* <div>Error</div> */}
                    </div>
                  </div>

                  {/* price section */}
                  <div className="w-full">
                    <div>
                      <div>
                        <p className="texts uppercase">price</p>
                        <select
                         className="w-full h-16 rounded-lg texts"
                         {...register("price", {
                            required: {
                              value: true,
                              message: "Trip type is required",
                            },
                          })}
                         >
                          <option>All Prices</option>
                          <option>$ 1000</option>
                          <option>$ 2000</option>
                          <option>$ 3000</option>
                          <option>$ 4000</option>
                          <option>$ 5000</option>
                        </select>
                      </div>
                      {/* <div>Error</div> */}
                    </div>
                  </div>
                </div>

                {/* btn section */}
                <div>
                  <input
                    type="submit"
                    value="Find flight"
                    className="w-full btn btnBlock"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </React.Fragment>
  );
};

export default Book;

import React, { createContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import html2canvas from 'html2canvas';

export const HotelDashboardContext = createContext();

function HotelDashboardProvider({ children }) {

  const [infos, setInfos] = useState([]);
  const infosCollectionRef = collection(db, "hotel_infos");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const htmlRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const getInfos = async () => {
      setLoading(true);
      const data = await getDocs(infosCollectionRef);
      setInfos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoading(false);
    };
    getInfos();
  }, []);

  const send = async (e) => {
    e.preventDefault();

    let generatedNumber;
    do {
      generatedNumber = generateNumber();
    } while (infos.some(info => info.confirmNumber === generatedNumber));

    try {
      await addDoc(infosCollectionRef, {
        destination: e.target[0].value,
        checkInDate: e.target[1].value,
        checkInTime: e.target[2].value,
        checkOutDate: e.target[3].value,
        checkOutTime: e.target[4].value,
        code: e.target[5].value,
        adults: e.target[6].value,
        nights: e.target[7].value,
        rooms: e.target[8].value,
        address: e.target[9].value,
        firstName: e.target[10].value,
        surName: e.target[11].value,
        email: e.target[12].value,
        confirmNumber: generatedNumber,
        
      });

      navigate(0);
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  };

  const generateNumber = () => {
    let randomNo = [];
    for (let i = 0; i < 10; i++) {
      randomNo.push(Math.floor(Math.random() * 9) + 1);
    }
    return randomNo.join('');
  };

  const handleDownload = async (info) => {
    setPreviewData(info);
    setIsModalOpen(true);
    setTimeout(async () => {
      const element = htmlRef.current;
      if (element) {
        const canvas = await html2canvas(element);
        const dataUrl = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'download.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, 1000);
  };

  const handleEdit = async (e, info) => {
    e.preventDefault();
    // setIsModalOpen2(true);
    // setIsModalOpen(false);

    try {
      const infoDoc = doc(db, "hotel_infos", info.id);
      await updateDoc(infoDoc, {
        destination: e.target[0].value,
        checkInDate: e.target[1].value,
        checkInTime: e.target[2].value,
        checkOutDate: e.target[3].value,
        checkOutTime: e.target[4].value,
        code: e.target[5].value,
        adults: e.target[6].value,
        nights: e.target[7].value,
        rooms: e.target[8].value,
        address: e.target[9].value,
        firstName: e.target[10].value,
        surName: e.target[11].value,
        email: e.target[12].value,
        // confirmNumber: generatedNumber,
      });

      navigate(0);
      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const deleteInfo = async (info) => {
    try {
      const infoDoc = doc(db, "hotel_infos", info.id);
      await deleteDoc(infoDoc);
      navigate(0);
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handlePreview = (info) => {
    setPreviewData(info);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const createModal = () => {
    setIsModalOpen2(true);
  };
  const closeCreateModal = () => setIsModalOpen2(false);

  return (
    <HotelDashboardContext.Provider value={{
      infos,
      loading,
      send,
      handleEdit,
      deleteInfo,
      handleDownload,
      handlePreview,
      closeModal,
      createModal,
      closeCreateModal,
      isModalOpen,
      setIsModalOpen,
      isModalOpen2,
      setIsModalOpen2,
      previewData,
      htmlRef,
    }}>
      {children}
    </HotelDashboardContext.Provider>
  );
}

export default HotelDashboardProvider;

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

const RedirectRoutHotel = ({ children }) => {
    const location = useLocation();
    const isAuthenticated = sessionStorage.getItem('authenticated') === 'true';
  
    if (!isAuthenticated) {
      return <Navigate to="/oscroh" state={{ from: location }} replace />;
    }
  
    return children;
  }

export default RedirectRoutHotel;
import imageGrid from '../../assets/imageGrid.png'

const Lounge = () => {
  return (
    <div className="lounge container flex">
      <div className="sectionContainer grid">
        <div className="imgDiv">
          <img src={imageGrid} alt="" />
        </div>

        <div className="textDiv">
          <h2>Unaccompanied Minor Lounge</h2>
          <div className="grids grid">

            <div className="singleGrid">
              <span className="gridTitle">
                Help through the airport
              </span>
              <p>Traveling alone can be daunting, especially for minors. Our Unaccompanied Minor Lounge offers a safe and comfortable space for young travelers, with dedicated staff to assist them through the airport process.</p>
            </div>

            <div className="singleGrid">
              <span className="gridTitle">
                Priority Boarding
              </span>
              <p>Experience the convenience of priority boarding. Skip the lines and settle into your seat ahead of the crowd, ensuring a stress-free start to your journey.</p>
            </div>

            <div className="singleGrid">
              <span className="gridTitle">
                Chauffeur-drive service
              </span>
              <p>Arrive in style with our chauffeur-drive service. Whether it’s to the airport or your final destination, enjoy the luxury and convenience of a personal driver.</p>
            </div>

            <div className="singleGrid">
              <span className="gridTitle">
                Care on Flight
              </span>
              <p>Our attentive flight crew is committed to providing exceptional care throughout your flight. From in-flight comfort to personalized service, we ensure a pleasant and enjoyable travel experience.</p>
            </div>

          </div>
        </div>



      </div>
    </div>
  )
}

export default Lounge
